import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars, faBriefcase, faEnvelope,faArrowLeft, faFont,
  faImages, faLink, faMapMarkerAlt, faPhoneAlt, faRedoAlt, faTimes, faGlobe, faVoicemail
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import Info from '../components/Info';
import ImagensPerson from '../components/ImagensPerson';
import { useAlert, positions } from "react-alert";

import templatebesmart from '../img/logos/logo_is_assessoria_Selos.jpg';
import templateinvestsmart from '../img/logos/logo_is_assessoria_Selos.jpg';
import templateinvestsmart2 from '../img/template-investsmart-logo-nova.png';
import templatebnkrio from '../img/template-bnkrio-logo.png';
import templateimoveis from '../img/BeSmart_Logos_imoveis.png';
import templateis from '../img/logos/logo_is_assessoria_Selos.jpg'

import placeholdImageUser from '../img/placeholder-image-icon_user.png';

import Footer from '../components/footer';


import '../components/styles.css';
import StepThree from './stepThree';


function AssessoriaIs() {

  const [nome, setNome] = useState(null);
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [cargo, setCargo] = useState('');
  const [cellcomercial, setCellcomercial] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [endereco1, setEndereco1] = useState('');
  const [url, setURL] = useState('');
  const [profile, setProfile] = useState(placeholdImageUser);
  const [template, setTemplate] = useState(1);
  var error = false;
  var aviso = "Preencha o(s) campo(s): ";
  var i;
  const alert = useAlert();
  let history = useHistory();

  useEffect(() => {
    if(template === 1){
      setCargo('Assessor de Investimentos')
    } else if (template === 4)  {
      setCargo('Assessor de Investimentos')
    } else {
      setCargo('')
    }
  }, [template])

  function dados(dado, label) {
    if (!dado) {
      error = true

      document.getElementById("info").setAttribute('hidden', true);
      document.getElementById("img-person").setAttribute('hidden', true);

      document.getElementById("info").removeAttribute('hidden');
      document.getElementById("images").classList.remove('active');
      document.getElementById("info-form").classList.add('active');

      let verificarDado = document.getElementById(label);
      verificarDado.classList.add('error-input');

      aviso += '' + label + ', ';
      
    }
  }

  function verificarDados () {
    error = false;
    dados(nome, "Nome")
    dados(sobrenome, "Sobrenome")
    dados(email, "Email")
    dados(cargo, "Cargo")
    dados(cellphone, "Celular")

    if (!error) {
      var templateID = document.querySelector('.body-layout').getAttribute('id');
      console.log(templateID);
      history.push(
        {
          pathname: "/passo-tres",
          state:{ profile, nome, sobrenome, email, cargo, cellcomercial, cellphone, endereco1, url, template, templateID}
        }
      );
    } else {
      aviso = aviso.slice(0, -2)
      alert.error(aviso, {timeout: 1500})
      aviso = "Preencha o(s) campo(s): ";
    }
  }

  function clear() {
    setNome('João');
    setSobrenome('da Silva');
    setEmail('nome.sobrenome@email.com,br');
    setCargo('Setor');
    setCellcomercial('(99) 9999-9999');
    setCellphone('(99) 99999-9999');
    setEndereco1('Av. Almirante Barroso');
    setURL('https://sualandingpage.com.br');
    setProfile(placeholdImageUser);

    document.getElementById("Nome").value = ""
    document.getElementById("Sobrenome").value = ""
    document.getElementById("Email").value = ""
    document.getElementById("Cargo").value = ""
    document.getElementById("telComercial").value = ""
    document.getElementById("Celular").value = ""
    document.getElementById("endereco").value = ""
    document.getElementById("url").value = ""
  } 


  // Função para alterar o navbar //


  function AlternarMenuText (e) {
    e.preventDefault();

    document.getElementById("info").removeAttribute('hidden',);

      document.getElementById("img-person").setAttribute('hidden', true);

      document.getElementById("images").classList.remove('active');

      document.getElementById("info-form").classList.add('active');

  }


  function AlternarMenuImgs(e) {
    e.preventDefault();

    document.getElementById("info").setAttribute('hidden', true);

    document.getElementById("img-person").removeAttribute('hidden');
    document.getElementById("images").classList.add('active');
    document.getElementById("info-form").classList.remove('active');
  }




  function closingFaqs() {
    document.getElementById('faqs-page').style.display = "none";
  };

  return (
    <>
    
    <div id="page" class="full-page">
    
      <div class="lateral-bar">
        <div class="top-bar-header row">
         
          <div class="col-6 painel-top-btn active " id="info-form" onClick={AlternarMenuText}>
            <i><FontAwesomeIcon icon={faFont} /></i>
          </div>
          <div class="col-6 painel-top-btn " id="images" onClick={AlternarMenuImgs}>
            <i><FontAwesomeIcon icon={faImages} /></i>
          </div>
        </div>



        <Info 
        setNome={setNome}
        setSobrenome={setSobrenome}
        setEmail={setEmail}
        setCargo={setCargo}
        setCellcomercial={setCellcomercial}
        setCellphone={setCellphone}
        setEndereco1={setEndereco1}
        setURL={setURL}
        template={template}
        cargo={cargo}
        />

        <ImagensPerson setProfile={setProfile} />

        

      </div>
      <div class="content-layout">
        <header className="header-layout">
        <div class="text-left pt-20"> <p><a href="/"><span class="voltar-editor"><i><FontAwesomeIcon icon={faArrowLeft} /></i>Voltar</span></a></p></div>
        <div class="text-center">

        <h3 className='content-body-header text-center  pre-header ' > Assinatura para o e-mail @assessoriainvestsmart.com.br</h3>
        </div>
        </header>
      
        <section className='text-center pre-header pt-20'>
      
        <p> Se você tem o email da assessoria InvestSmart você esta no lugar certo.<br/> Preencha as informações no menu lateral e  finalize a sua assinatura. </p>

      </section>

          <div class="body-layout">
            <div class="window-screen">
              <div class="top-window">
                <span class="btn-close"></span>
                <span class="btn-min"></span>
                <span class="btn-max"></span>
              </div>
              <div class="top-infomessage-window">
                <span class="to-message">Para: <strong style={{ paddingLeft: 5 }}>Seu destinatário</strong></span>
                <span class="subject-message">Assunto: <strong style={{ paddingLeft: 5 }}>Veja minha nova assinatura de e-mail</strong></span>
              </div>
              <div class="content-window">
                <div class="body-window">
                  <div class="info-member-window">
                    <div class="col-lg-5 col-md-6">
                      <div class="col-4" style={{padding: '0'}}>
                          <div class="profile-img">
                            <img id="cropper-img" className="borderimg" src={profile} />
                          </div>
                      </div>
                      <div class="col-8" style={{paddingLeft: '20px'}}>
                          <div class="profile-name">
                            <h5><strong>{nome || 'João'} {sobrenome || 'da Silva'}</strong></h5>
                          </div>
                          <div class="profile-info">
                              <span class="graduation"><strong>{cargo || 'Setor'}</strong></span>
                          </div>
                      </div>
                    </div>
                    <hr class="vertical"/>
                      
                    <div class="col-lg-7 col-md-6" style={{paddingLeft: '50px'}}>
                      <div class="contact-block">

                          {
                            !nome ? (<span class="phone">{cellphone || '(99) 99999-9999'}<span className="alinhamento-span"> | {cellcomercial || ' (99) 9999-9999'}</span></span>) : null
                          }

                          {
                            nome && cellcomercial.length == 0 ? (<span class="phone">{cellphone || '(99) 99999-9999'}<span hidden> | {cellcomercial || '(99) 9999-9999'}</span></span>) : null
                          }

                          {
                            nome && cellcomercial.length > 0 && cellphone.length > 0 ? (<span class="phone">{cellphone || 'Telefone Celular'}<span className="alinhamento-span"> | {cellcomercial || 'Telefone Comercial'}</span></span>) : null
                          }

                          <span class="mail">{email || 'nome.sobrenome@investsmart.com.br'}</span>

                          {
                            !nome || url.length > 0 ? (<a target="_blank" style={{color:'inherit'}} href={url || 'https://sualandingpage.com.br'}><span class="extra-description">{url || 'https://sualandingpage.com.br'}</span></a>) : null
                          }

                          {
                            !nome || endereco1.length > 0 ? (<span class="endereco" >{endereco1 || 'Av. Almirante Barroso,81, Centro - RJ'}</span>) : null
                          }

                      </div>
                    </div>
                  </div>
               {/*    <hr style={{margin: '40px 0 0'}}/> */}

                  {
                    template === 0 ? (
                      <div class="info-company-window">
                        <div class="company-profile-logo d-block">
                          <img src={templatebesmart}/>
                        </div>

                         <div class="company-slogan">
                           <h3 class="mb-0 mt-20 text-left py-2"></h3> 
                        </div> 

                        <div class="social-media">
                          <div class="row contents">
                            <span id="glob" class="glob-icon"><a id="glob-link" target="_blank" href="https://besmart.com.br/"><i><FontAwesomeIcon icon={faGlobe} /></i></a></span>
                            <span id="face" class="facebook-icon"><a id="face-link" target="_blank" href="https://www.facebook.com/grupobesmart"><i><FontAwesomeIcon icon={faFacebookF} /></i></a></span>
                            <span id="linke" class="linkedin-icon"><a id="linke-link" target="_blank" href="https://www.linkedin.com/company/besmartis/"><i><FontAwesomeIcon icon={faLinkedinIn} /></i></a></span>
                            <span id="insta" class="instagram-icon"><a id="insta-link" target="_blank" href="https://www.instagram.com/be.smart.is/"><i><FontAwesomeIcon icon={faInstagram} /></i></a></span>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }

                  {
                    template === 1 ? (
                      <div class="info-company-window">
                        <div class="company-profile-logo w-40" >
                          <img class="img-fluid" src={templateinvestsmart}/>
                          {/* <div class="company-slogan">
                            <h3 class="mb-0 text-left py-2">Pensou em investimentos, pense InvestSmart</h3>
                          </div> */}
                     
                         
                        </div>
                      
                        

                        <div class="social-media">
                          <div class="row contents">
                            <span id="glob" class="glob-icon"><a id="glob-link" target="_blank" href="https://investsmart.com.br/"><i><FontAwesomeIcon icon={faGlobe} /></i></a></span>
                            <span id="face" class="facebook-icon"><a id="face-link" target="_blank" href="https://www.facebook.com/InvestSmartXP"><i><FontAwesomeIcon icon={faFacebookF} /></i></a></span>
                            <span id="linke" class="linkedin-icon"><a id="linke-link" target="_blank" href="https://www.linkedin.com/company/investsmartxp"><i><FontAwesomeIcon icon={faLinkedinIn} /></i></a></span>
                            <span id="insta" class="instagram-icon"><a id="insta-link" target="_blank" href="https://www.instagram.com/investsmart.xp/"><i><FontAwesomeIcon icon={faInstagram} /></i></a></span>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }

                  {
                    template === 2 ? (
                      <div class="info-company-windows">
                        <div class="company-profile-logo d-block ">
                          <img src={templateinvestsmart}/>
                
                        </div>
                        <div  class="company-slogan">
                          {/* <h3 class="mb-0 text-left py-2">O primeiro marketplace financeiro do Brasil.</h3> */}
                        </div>

                        <div class="social-media">
                          <div class="row contents">
                            <span id="glob" class="glob-icon"><a id="glob-link" target="_blank" href="https://investsmart.com.br/"><i><FontAwesomeIcon icon={faGlobe} /></i></a></span>
                            <span id="face" class="facebook-icon"><a id="face-link" target="_blank" href="https://www.facebook.com/InvestSmartXP"><i><FontAwesomeIcon icon={faFacebookF} /></i></a></span>
                            <span id="linke" class="linkedin-icon"><a id="linke-link" target="_blank" href="https://www.linkedin.com/company/investsmartxp"><i><FontAwesomeIcon icon={faLinkedinIn} /></i></a></span>
                            <span id="insta" class="instagram-icon"><a id="insta-link" target="_blank" href="https://www.instagram.com/investsmart.xp/"><i><FontAwesomeIcon icon={faInstagram} /></i></a></span>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    template === 3 ? (
                      <div class="info-company-window">
                        <div class="company-profile-logo d-block">
                          <img src={templateimoveis}/>
                        </div>
                       
                        <div class="company-slogan">
                           <h3 class="mb-0 mt-20 text-left py-2"></h3> 
                        </div> 

                        <div class="social-media">
                          <div class="row contents">
                            <span id="glob" class="glob-icon"><a id="glob-link" target="_blank" href="https://www.besmartimoveis.com.br/"><i><FontAwesomeIcon icon={faGlobe} /></i></a></span>
                            <span id="face" class="facebook-icon"><a id="face-link" target="_blank" href="https://www.facebook.com/besmartimoveis"><i><FontAwesomeIcon icon={faFacebookF} /></i></a></span>
                            <span id="linke" class="linkedin-icon"><a id="linke-link" target="_blank" href="https://www.linkedin.com/company/besmartimoveis"><i><FontAwesomeIcon icon={faLinkedinIn} /></i></a></span>
                            <span id="insta" class="instagram-icon"><a id="insta-link" target="_blank" href="https://www.instagram.com/be.smart_imoveis/"><i><FontAwesomeIcon icon={faInstagram} /></i></a></span>
                            <span id="youtube" class="youtube-icon"><a id="youtube-link" target="_blank" href=" https://www.youtube.com/channel/UCx3OKKUpY3jwu_KQT15akAg"><i><FontAwesomeIcon icon={faYoutube}/> </i></a></span>

                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    template === 4 ? (
                      <div class="info-company-window">
                        <div class="company-profile-logo d-block logo-is">
                          <img src={templateis}  />
                        </div>
                       
                        <div class="company-slogan">
                           <h3 class="mb-0 mt-20 text-left py-2"></h3> 
                        </div> 

                        <div class="social-media">
                          <div class="row contents">
                            <span id="glob" class="glob-icon"><a id="glob-link" target="_blank" href="https://www.besmartimoveis.com.br/"><i><FontAwesomeIcon icon={faGlobe} /></i></a></span>
                            <span id="face" class="facebook-icon"><a id="face-link" target="_blank" href="https://www.facebook.com/besmartimoveis"><i><FontAwesomeIcon icon={faFacebookF} /></i></a></span>
                            <span id="linke" class="linkedin-icon"><a id="linke-link" target="_blank" href="https://www.linkedin.com/company/besmartimoveis"><i><FontAwesomeIcon icon={faLinkedinIn} /></i></a></span>
                            <span id="insta" class="instagram-icon"><a id="insta-link" target="_blank" href="https://www.instagram.com/be.smart_imoveis/"><i><FontAwesomeIcon icon={faInstagram} /></i></a></span>
                            <span id="youtube" class="youtube-icon"><a id="youtube-link" target="_blank" href=" https://www.youtube.com/channel/UCx3OKKUpY3jwu_KQT15akAg"><i><FontAwesomeIcon icon={faYoutube}/> </i></a></span>

                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="footer-newsletter-opt">
            <div class="create-newsletter">
              {/** 
            <Link to={{
                pathname:"/passo-tres",
                state:{ profile, nome, sobrenome, email, cargo, cellcomercial, cellphone, endereco1, url, template}}}></Link>
            */}
            <span class="create-btn bg-success" onClick={verificarDados}>Finalizar Assinatura</span>
            </div>
            <div class="clear-all" id="clear-btn">
              <i><FontAwesomeIcon icon={faRedoAlt} /></i>
              <span class="clean-text" onClick={clear}>Apagar todos os campos de entrada</span>
            </div>
          </div>
        </div>
    </div>

    {/* FAQ */}

           <Footer/> 

</>
  );
}

export default AssessoriaIs;
